














import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
  name: 'blog',
  data() {
    return {
      posts: {},
    };
  },
  methods: {
    // async getPostsJSON() {
    //   try {
    //     // let data = 'https://medium.com/feed/@maybelatergames.help'
    //     // data.replace('/', '%2F').replace(':', '%3A').replace('@','%40')
    //     // const res1 = await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F
// %2Fmedium.com%2Ffeed%2F%40maybelatergames.help');
    //     // const res = await this.axios.get(
    //     //   // `https://api.rss2json.com/v1/api.json?rss_url=${data}`
    //     //   'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40LanceUlanoff',
    //     //   // { params: {
    //     //   //   rss_url: data }
    //     //   // }
    //     //   )
    //     // console.log(res1.data.data);
    //     this.posts = res1.data.data;
    //     // this.cardsComparesd = res.data.data.comparisonsTotal.comparisonsTotal
    //   } catch (e) {
    //     // console.log('err', e);
    //   }
    // },
  },
  mounted() {
    // this.getPostsJSON();
    // axios
    //   .get('https://api.coindesk.com/v1/bpi/currentprice.json')
    //   .then(response => (this.info = response))
    axios
      .get(
        `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40maybelatergames.help`,
      )
      .then((response) => {
        // JSON responses are automatically parsed.
        this.posts = response.data;
      })
      .catch((e) => {
        // this.errors.push(e);
      });
    },
  });
